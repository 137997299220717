<script setup lang="ts">
const { t } = useI18n()
const route = useRoute()
const obstacle = useObstacle()
const { isModalOpen } = useNavigation()
const { $nps, $unleash, $pwaBanner, $ROUTE, $auth } = useNuxtApp()

const [delayOver, setDelayOver] = useToggle(false)

const rating = ref<number>()

const { start: startDelayOver, stop: stopDelayOver } = useTimeoutFn(
  setDelayOver,
  30000,
  { immediate: false },
)
const { start: startCloseBanner, stop: stopCloseBanner } = useTimeoutFn(
  closeBanner,
  5000,
  { immediate: false },
)

const isDenyRoute = computed(() =>
  [
    $ROUTE.CONTACT_US,
    $ROUTE.CONTACT_US_SUCCESS,
    $ROUTE.CONTACT_US_ERROR,
  ].includes(route.path),
)

const show = computed(
  () =>
    $nps.showed &&
    $unleash.features['show-feedback-banner'] &&
    $pwaBanner.showed &&
    !isDenyRoute.value,
)
const showWithDelay = computed(
  () => show.value && delayOver.value && !isModalOpen.value,
)

const isNoRating = computed(() => rating.value === undefined)
const isHighRating = computed(() => rating.value && rating.value > 6)
const bannerTitle = computed(() => {
  return isHighRating.value || isNoRating.value
    ? t('components.shareFeedback.recommend')
    : t('components.shareFeedback.thankForFeedback')
})

whenever(
  show,
  () => {
    startDelayOver()
  },
  { immediate: true },
)

watch(
  () => route.path,
  (newVal, oldVal) => {
    if (newVal !== oldVal && showWithDelay.value) {
      closeBanner()
    }
  },
)

$auth.on('logout', () => {
  stopDelayOver()
  stopCloseBanner()
  setDelayOver(false)
})

function provideFeedback(feedbackRating: string) {
  rating.value = parseInt(feedbackRating)
  $nps.vote(rating.value)
  $nps.setFeedback({
    lastBoxClosingDate: new Date(),
    feedbackGiven: rating.value !== undefined,
  })

  if (isHighRating.value) {
    startCloseBanner()
  }
}

function closeBanner() {
  stopCloseBanner()
  $nps.setFeedback({ lastBoxClosingDate: new Date(), feedbackGiven: false })
  $nps.close()
}
</script>

<template>
  <div
    v-if="showWithDelay"
    class="nps-safe-area fixed inset-x-0 md:bottom-0 z-10 flex justify-center gap-4 bg-bgr-700 px-7 py-6 shadow-top"
    :style="{ bottom: obstacle.obstaclePixelHeight.value }"
  >
    <div class="flex flex-col gap-y-4 sm:align-middle">
      <p
        class="pr-10 md:pr-0 text-center text-base font-medium leading-4 text-white sm:text-center"
      >
        {{ bannerTitle }}
      </p>

      <NpsBannerRating v-if="isNoRating" @click="provideFeedback" />
      <NpsBannerHighRating v-else-if="isHighRating" />
      <NpsBannerLowRating
        v-else
        @click="navigateTo(`${$ROUTE.CONTACT_US}?type=Feedback`)"
      />
    </div>

    <BasicAccessibleIconButton
      class="absolute top-5 right-5"
      icon-class="text-txt-white"
      size="lg"
      graphic="close"
      :label="$t('components.actions.close')"
      @click="closeBanner"
    />
  </div>
</template>
